import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import {
  ResidenceCardPreview,
  ResidenceCardStyled,
  ResidenceContent,
  ResidencePrice,
  ResidenceTitle
} from './styled';
import SvgIconLocation from '../../../public/static/images/residences/icon-location.svg';
import SvgIconDeadline from '../../../public/static/images/residences/icon-key.svg';
import SvgIconMetro from '../../../public/static/images/residences/metro.svg';
import SvgIconBrand from '../../../public/static/images/residences/brand.svg';
import { ResidenceParam } from './ResidenceParam';
import useScreenSize from '../../../hooks/useScreenSize';
import { CurrencySymbol } from '../../ui/ItemCard/CurrencySymbol';
import { CardPreviewImage } from '../../ui/ItemCard/styled';

export const ResidenceCard = ({
  path,
  coverUrl,
  price,
  name,
  shortAddress,
  address,
  deadline,
  cypress,
  metroName,
  companyName,
  hideExcessItems = false
}) => {
  const { isSmall } = useScreenSize();
  const formatPrice = price.total.minimum.toLocaleString().replace(/,/g, ' ');
  const priceValue = price.total.minimum.toLocaleString('ru-RU');

  const fontSizes = {
    price: isSmall ? 'h5_new' : 'h4_new',
    title: 'h3'
  };

  return (
    <ResidenceCardStyled
      $hideExcessItems={hideExcessItems}
      href={path}
      className="item-card_residence"
      cypress={cypress}
      stat="residence-card-link"
    >
      <ResidenceCardPreview>
        <CardPreviewImage
          src={coverUrl}
          alt=""
          priority
          width={100}
          height={75}
        />
      </ResidenceCardPreview>
      <ResidenceContent>
        <ResidencePrice tag={fontSizes.price}>
          <Trans
            i18nKey="sections.residences.from"
            values={{ price: priceValue }}
            components={{
              0: <span>{priceValue}</span>,
              1: <CurrencySymbol currency={price.currency} />
            }}
          >
            <span>{formatPrice}</span>
            <CurrencySymbol currency={price.currency} />
          </Trans>
        </ResidencePrice>
        <ResidenceTitle tag={fontSizes.title}>{name}</ResidenceTitle>
        {!isSmall && companyName && (
          <ResidenceParam
            icon={<SvgIconBrand />}
            param={companyName}
            isMobile={isSmall}
          />
        )}
        <ResidenceParam
          icon={<SvgIconLocation />}
          param={shortAddress ? `${shortAddress}` : `${address}`}
          isMobile={isSmall}
        />
        {!isSmall && metroName && (
          <ResidenceParam
            icon={<SvgIconMetro />}
            param={metroName}
            isMobile={isSmall}
          />
        )}
        {deadline && (
          <ResidenceParam
            icon={<SvgIconDeadline />}
            param={deadline}
            isMobile={isSmall}
          />
        )}
      </ResidenceContent>
    </ResidenceCardStyled>
  );
};

ResidenceCard.propTypes = {
  t: PropTypes.func,
  path: PropTypes.string,
  name: PropTypes.string,
  companyName: PropTypes.string,
  metroName: PropTypes.string,
  price: PropTypes.object,
  coverUrl: PropTypes.string,
  shortAddress: PropTypes.string,
  address: PropTypes.string,
  deadline: PropTypes.string,
  offset: PropTypes.number,
  overflow: PropTypes.bool,
  cypress: PropTypes.string,
  hideExcessItems: PropTypes.bool
};
