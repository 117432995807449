import styled from 'styled-components';
import { CardPreview, itemCardStyles } from '../../ui/ItemCard/styled';
import { Typography } from '../../ui/Typography';
import { ellipsisText, FlexColumn } from '../../../styled/common';
import { CustomLink } from '../../CustomLink';

export const ResidenceCardStyled = styled(CustomLink)`
  ${itemCardStyles};
  margin-bottom: 0;
`;

export const ResidenceTitle = styled(Typography)`
  overflow: hidden;
  margin: 5px 0 0;
`;

export const ResidencePrice = styled(Typography)`
  white-space: nowrap;
  margin: 0;
  display: flex;
  align-items: center;

  span:last-child {
    margin-left: 2px;
  }
`;

export const ResidenceCardPreview = styled(CardPreview)`
  @media (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding-top: 71%;
  }
`;

export const ResidenceContent = styled(FlexColumn)`
  padding: 5px 0;

  & > *:not(:first-child) {
    margin-top: 5px;
  }
`;

export const StyledResidenceParam = styled(Typography)`
  display: flex;
  align-items: center;

  & > div {
    justify-content: flex-start;
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  & > span {
    ${ellipsisText}
  }
`;
